import React from "react";
import { Link } from "react-router-dom";
import { MdChevronRight } from "react-icons/md";
import { Flex, Heading, Link as ChakraLink, Box } from "@chakra-ui/core";
import moment from "moment";

const AuctionCard = ({
  id,
  description,
  endDate,
  orgName,
  sponsorDesc,
  sponsorName,
  startDate,
  timeStart,
  timeEnd,
}) => {
  return (
    <Flex
      flexDirection="column"
      boxShadow="0 0 5px 2px rgba(0,0,0,0.2)"
      rounded="md"
      position="relative"
    >
      <ChakraLink
        as={Link}
        color="teal.500"
        fontSize="xl"
        to={`/auction/${id}`}
        title="Visit Auction"
        position="absolute"
        top={0}
        right={0}
      >
        <MdChevronRight />
      </ChakraLink>
      <Box
        backgroundColor="brand.200"
        p={2}
        borderTopRightRadius="md"
        borderTopLeftRadius="md"
      >
        <Heading as="h4" color="brand.800">
          {orgName}
        </Heading>
      </Box>
      <Box
        fontSize="sm"
        borderBottomStyle="solid"
        borderBottomWidth={1}
        borderBottomColor="gray.300"
        mb={2}
        p={2}
      >
        {description}
      </Box>

      <Box p={2} fontWeight="bold" color="brand.900">
        by {sponsorName}
      </Box>
      <Box fontSize="xs" p={2} flex={1}>
        {sponsorDesc}
      </Box>
    </Flex>
  );
};

export default AuctionCard;

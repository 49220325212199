import { useState, useEffect } from "react";

import firebase from "../firebase/firebase";

function useData() {
  const db = firebase.db;
  const [auctions, setAuctions] = useState([]);
  useEffect(() => {
    db.collection("auctions").onSnapshot((snapshot) => {
      setAuctions(
        snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
      );
    });
  }, [db]);

  return { auctions };
}

export default useData;

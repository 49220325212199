import { theme } from "@chakra-ui/core";

const rocketTheme = {
  ...theme,
  colors: {
    ...theme.colors,
    brand: {
      900: "#00483D",
      800: "#067a69",
      700: "#3c9789",
      600: "#64aca2",
      500: "#85beb5",
      400: "#a0cdc6",
      300: "#b9dad5",
      200: "#cfe5e2",
      100: "#e3f0ee",
      0: "#f6fafa",
    },
  },
};

export default rocketTheme;

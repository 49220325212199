import React, { useContext } from "react";
import AuctionCard from "../../components/auctionCard/auctionCard";
import { Grid } from "@chakra-ui/core";
import StoreContext from "../../store/storeContext";

const Home = () => {
  const { auctions } = useContext(StoreContext);

  return (
    <Grid
      gridTemplateColumns={[
        "1fr",
        "repeat(2, 1fr)",
        "repeat(3, 1fr)",
        "repeat(4, 1fr)",
        "repeat(5, 1fr)",
      ]}
      gap={6}
      p={8}
    >
      {auctions.map((auction) => (
        <AuctionCard key={auction.id} {...auction} />
      ))}
    </Grid>
  );
};

export default Home;

import React from "react";
import Home from "../../pages/home/home";
import Auction from "../../pages/auction/auction";
import Item from "../../pages/item/item";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "../Auth/Login";
import ForgotPassword from "../Auth/ForgotPassword";
import useAuth from "../Auth/useAuth";
import FirebaseContext from "../../firebase/context";
import firebase from "../../firebase/firebase";
import Header from "../header/header";
import Footer from "../footer/footer";
import { Flex, Box } from "@chakra-ui/core";
import StoreContext from "../../store/storeContext";
import useData from "../../hooks/useData";
import Donate from "../../pages/donate/donate";
import ComingSoon from "../../pages/comingSoon/comingSoon";

function App() {
  const user = useAuth();
  const { auctions } = useData();
  return (
    <Router>
      <FirebaseContext.Provider value={{ user, firebase }}>
        <StoreContext.Provider value={{ auctions }}>
          <Switch>
            <Route exact path="/">
              <ComingSoon />
            </Route>
            <Flex flexDirection="column" h="full">
              <Header />
              <Box as="main" flex={1}>
                <Route exact path="/home">
                  <Home />
                </Route>
                <Route exact path="/auction/:id">
                  <Auction />
                </Route>
                <Route exact path="/item/:id">
                  <Item />
                </Route>
                <Route path="/donate" component={Donate} />
                <Route path="/login" component={Login} />
                <Route path="/forgot" component={ForgotPassword} />
              </Box>
              <Footer />
            </Flex>
          </Switch>
        </StoreContext.Provider>
      </FirebaseContext.Provider>
    </Router>
  );
}

export default App;

import React from "react";
import { Flex, Image, Box, Heading } from "@chakra-ui/core";
import { FaDonate, FaShippingFast } from "react-icons/fa";
import { RiAuctionLine } from "react-icons/ri";
import DonateForm from "../../components/donateForm/donateForm";

const Donate = () => {
  return (
    <Flex flexDirection="column" height="full">
      <Flex
        flex={1}
        backgroundColor="#111D4A"
        justifyContent="center"
        alignItems="center"
        position="relative"
      >
        <Flex
          width="90%"
          maxWidth="900px"
          fontSize="xl"
          fontWeight="bold"
          textShadow="0 0 4px black"
          color="white"
          zIndex={1}
          flexDirection="column"
        >
          <Heading color="brand.300">Donate Now!</Heading>
          <p>
            Direct your whole effort toward the happiness of those who are
            despondent, bestow food upon the hungry, clothe the needy, and
            glorify the humble. Be a helper to every helpless one, and manifest
            kindness to your fellow creatures...
          </p>
          <Flex
            width="100%"
            justifyContent="space-around"
            alignItems="center"
            mt={4}
            flexDirection={{ base: "column", md: "row" }}
          >
            <Flex flex={1} m={4}>
              <Flex
                borderRightStyle="solid"
                borderRightWidth={1}
                borderRightColor="brand.100"
                fontSize="6xl"
                flex={1}
                justifyContent="center"
                alignItems="center"
              >
                1
              </Flex>
              <Box flex={1}>
                <Box fontSize="4xl">
                  <Box as={FaDonate} mx="auto" my={0} />
                </Box>
                <Box p={2} fontSize="xl" textAlign="center">
                  Tell us about your donation!
                </Box>
              </Box>
            </Flex>

            <Flex flex={1} m={4}>
              <Flex
                borderRightStyle="solid"
                borderRightWidth={1}
                borderRightColor="brand.100"
                fontSize="6xl"
                flex={1}
                justifyContent="center"
                alignItems="center"
              >
                2
              </Flex>
              <Box flex={1}>
                <Box fontSize="4xl">
                  <Box as={RiAuctionLine} mx="auto" my={0} />
                </Box>
                <Box p={2} fontSize="xl" textAlign="center">
                  We auction it to the highest bidder!
                </Box>
              </Box>
            </Flex>

            <Flex flex={1} m={4}>
              <Flex
                borderRightStyle="solid"
                borderRightWidth={1}
                borderRightColor="brand.100"
                fontSize="6xl"
                flex={1}
                justifyContent="center"
                alignItems="center"
              >
                3
              </Flex>
              <Box flex={1}>
                <Box fontSize="4xl">
                  <Box as={FaShippingFast} mx="auto" my={0} />
                </Box>
                <Box p={2} fontSize="xl" textAlign="center">
                  You ship the item to the winner!
                </Box>
              </Box>
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      <Flex flex={2} p={8}>
        <DonateForm />
      </Flex>
    </Flex>
  );
};

export default Donate;

import React from "react";
import { Box } from "@chakra-ui/core";
import LogoPlace from "./logoPlace";
import ComingSoonForm from "./comingSoonForm";

const ComingSoon = () => {
  return (
    <Box h="100%" w="full" bg="teal.500" overflow="auto">
      <Box w="100%" maxWidth="3xl" bg="teal.500" color="black" my={0} mx="auto">
        <LogoPlace />
        <ComingSoonForm />
      </Box>
    </Box>
  );
};

export default ComingSoon;

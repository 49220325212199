import React, { useState, useContext, useEffect } from "react";
import {
  Input,
  InputLeftElement,
  Icon,
  InputGroup,
  FormControl,
  Button,
  Divider,
  Flex,
  Box,
  FormLabel,
  FormHelperText,
} from "@chakra-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";

import FirebaseContext from "../../firebase/context";

const ComingSoonForm = () => {
  const [name, setName] = useState("");

  const { firebase } = useContext(FirebaseContext);

  useEffect(() => {
    const name = localStorage.getItem("registered_name");
    if (name) {
      setName(name);
    }
  }, [name]);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email address is required"),
    }),
    onSubmit: async (values) => {
      firebase.db
        .collection("registrations")
        .add(values)
        .then(() => {
          setName(values.name);
          localStorage.setItem("registered_name", values.name);
          formik.resetForm();
        });
    },
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      {name && (
        <Box textAlign="center" color="orange.700">
          Thank you for registering {name}. We will be in touch soon. You may
          register another email if you like.
        </Box>
      )}
      <Flex flexDirection={["column", "row"]} p="4">
        <FormControl mb={4} flex={1} mr={[0, 4]} position="relative">
          <FormLabel
            htmlFor="name"
            display={formik.values.name ? "block" : "none"}
            color="gray.500"
            position="absolute"
            zIndex={1}
            left="40px"
            fontSize="xs"
          >
            Your name
          </FormLabel>
          <InputGroup>
            <InputLeftElement children={<Icon name="info" />} />
            <Input
              variant="Outline"
              type=" name "
              id="name"
              placeholder="Your Name"
              aria-label="Name"
              name="name"
              {...formik.getFieldProps("name")}
            />
          </InputGroup>
        </FormControl>

        <FormControl isRequired flex={1} position="relative">
          <FormLabel
            htmlFor="email"
            display={formik.values.email ? "block" : "none"}
            color="gray.500"
            position="absolute"
            zIndex={1}
            left="40px"
            fontSize="xs"
          >
            Email
          </FormLabel>
          <InputGroup>
            <InputLeftElement children={<Icon name="email" />} />
            <Input
              variant="Outline"
              type="email"
              placeholder="Email"
              aria-label="Email"
              name="email"
              {...formik.getFieldProps("email")}
            />
          </InputGroup>
          {formik.touched.email && formik.errors.email ? (
            <Box as="p" fontSize="xs" color="red.700" fontStyle="italic">
              {formik.errors.email}
            </Box>
          ) : null}
        </FormControl>
      </Flex>
      <Divider borderColor="red.100" />
      <Flex w="100%" alignItems="center" justifyContent="center" p={4}>
        <Button
          variant="Outline"
          leftIcon="arrow-forward"
          type="submit"
          boxShadow="sm"
          _hover={{ bg: "blue.400" }}
          _active={{ boxShadow: "lg" }}
          _focus={{ boxShadow: "outline" }}
        >
          Submit!
        </Button>
        <FormHelperText
          textAlign="center"
          ml="5"
          color="gray.700"
          fontSize={["xs", "sm"]}
        >
          We will notify you when we are live! <br />
          We never share your email so no worries...{" "}
        </FormHelperText>
      </Flex>
    </form>
  );
};
export default ComingSoonForm;

import React from "react";
import { Box } from "@chakra-ui/core";

const Footer = () => {
  return (
    <Box
      as="footer"
      textAlign="center"
      color="gray.400"
      fontSize="sm"
      borderTop="solid"
      borderTopColor="gray.200"
      borderTopWidth={1}
      py={2}
    >
      &copy; {new Date().getFullYear()} Yodacom
    </Box>
  );
};

export default Footer;

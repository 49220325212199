import React from "react";

import { Flex, Box, Image, Heading, Text } from "@chakra-ui/core";
import Logo from "../../images/logo.svg";

const LogoPlace = () => {
  const title = `Give Rocket`;
  const subText = `Helping great causes by enabling awesome donors to provide gifts of things
    or services for auction - virtually or physically - to causes they believe in`;

  return (
    <Flex justifyContent="center" alignItems="center" p="2">
      <Box>
        <Box mt={10}>
          <Text as="h1" fontSize={{ xs: "2xl", md: "4xl" }}>
            Coming Soon ...
          </Text>
        </Box>
        <Flex>
          <Heading
            fontSize={{ xs: "4em", sm: "6em", md: "8em", lg: "10em" }}
            flex={1}
          >
            {title}
          </Heading>
          <Image
            size="75%"
            maxWidth={200}
            src={Logo}
            m="auto"
            alt="Logo of Give Rocket"
            flex={1}
          />
        </Flex>
        <Heading fontSize="md" textAlign="center" maxWidth="25" mt="2" mb="8">
          {subText}
        </Heading>
      </Box>
    </Flex>
  );
};
export default LogoPlace;

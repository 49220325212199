import React, { useState, useContext } from "react";

import FirebaseContext from "../../firebase/context";
import {
  Box,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Flex,
  Button,
  Link as ChakraLink,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from "@chakra-ui/core";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

function ForgotPassword() {
  const { firebase } = useContext(FirebaseContext);
  const [isPasswordReset, setIsPasswordReset] = useState(false);
  const [passwordResetError, setPasswordResetError] = useState(null);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email address is required"),
    }),
    onSubmit: async (values) => {
      try {
        await firebase.resetPassword(values.email);
        setIsPasswordReset(true);
        setPasswordResetError(null);
      } catch (err) {
        console.error("Error sending email", err);
        setPasswordResetError(err.message);
        setIsPasswordReset(false);
      }
    },
  });
  return (
    <Box w="65%" mt={16} mx="auto">
      <Heading as="h2" mb={8}>
        Reset Password
      </Heading>
      <form onSubmit={formik.handleSubmit}>
        <FormControl isRequired mb={8}>
          <FormLabel htmlFor="email">Email</FormLabel>
          <Input
            id="email"
            placeholder="Your email address"
            {...formik.getFieldProps("email")}
            autocomplete="off"
            type="email"
          />
        </FormControl>
        {formik.touched.email && formik.errors.email ? (
          <p className="text-red-500 text-xs italic">{formik.errors.email}</p>
        ) : null}

        <Flex justifyContent="flex-end" alignItems="center">
          <Button
            type="submit"
            mr={6}
            variantColor="green"
            disabled={formik.isSubmitting}
          >
            Submit
          </Button>
          <ChakraLink as={Link} to="/login" color="teal.500">
            Cancel
          </ChakraLink>
        </Flex>
      </form>
      {isPasswordReset && (
        <Alert
          status="success"
          variant="subtle"
          flexDirection="column"
          justifyContent="center"
          textAlign="center"
          height="200px"
          mt={8}
        >
          <AlertIcon size="40px" mr={0} />
          <AlertTitle mt={4} mb={1} fontSize="lg">
            Email Sent!
          </AlertTitle>
          <AlertDescription maxWidth="sm">
            Please check your email for instructions on resetting your password.
          </AlertDescription>
        </Alert>
      )}
      {passwordResetError && (
        <Alert status="error" mt={8}>
          <AlertIcon />
          {passwordResetError}
        </Alert>
      )}
    </Box>
  );
}

export default ForgotPassword;

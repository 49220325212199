import React from "react";
import {
  Box,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Flex,
  Button,
  Textarea,
  RadioGroup,
  Radio,
  NumberInput,
  NumberInputField,
  Checkbox,
} from "@chakra-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";

const DonateForm = () => {
  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      condition: "new",
      startingBid: "",
      purchasePrice: "",
      quantity: 1,
      organization: "",
      shopOrDeliver: "ship",
      showCredit: false,
      readStatement: false,
    },
    validationSchema: Yup.object({}),
    onSubmit: async (values) => {
      console.log(values);
    },
  });

  return (
    <Box width="90%" maxWidth="900px" mx="auto" my={0}>
      <Heading as="h2" mb={8}>
        Donate an item!
      </Heading>
      <form onSubmit={formik.handleSubmit} className="flex flex-column">
        <FormControl mb={8} isRequired>
          <FormLabel htmlFor="name">Name</FormLabel>
          <Box fontSize="xs" color="gray.400">
            Enter a descriptive name for the item.
          </Box>
          <Input
            id="name"
            placeholder="Item name"
            {...formik.getFieldProps("name")}
          />
        </FormControl>
        {formik.touched.name && formik.errors.name ? (
          <p className="text-red-500 text-xs italic">{formik.errors.name}</p>
        ) : null}

        <FormControl isRequired mb={8}>
          <FormLabel htmlFor="description">Description</FormLabel>
          <Box fontSize="xs" color="gray.400">
            Write a one paragraph description of the item.
          </Box>
          <Textarea
            size="md"
            id="description"
            placeholder="Description of the item."
            {...formik.getFieldProps("description")}
          />
        </FormControl>
        {formik.touched.description && formik.errors.description ? (
          <p className="text-red-500 text-xs italic">
            {formik.errors.description}
          </p>
        ) : null}
        <FormControl isRequired mb={8}>
          <FormLabel htmlFor="condition">Item Condition</FormLabel>
          <RadioGroup
            id="condition"
            {...formik.getFieldProps("condition")}
            isInline
            variantColor="brand"
          >
            <Radio value="new">New</Radio>
            <Radio value="like_new">Like new</Radio>
            <Radio value="very_good">very Good</Radio>
            <Radio value="good">Good</Radio>
            <Radio value="acceptable">Acceptable</Radio>
            <Radio value="rough"> A bit rough</Radio>
          </RadioGroup>
        </FormControl>

        <Flex
          flexDirection={{ base: "column", md: "row" }}
          justifyContent="space-between"
        >
          <FormControl mb={8} isRequired>
            <FormLabel htmlFor="startingBid">Starting Bid</FormLabel>
            <Box fontSize="xs" color="gray.400">
              Enter the minimum starting bid for this item.
            </Box>
            <NumberInput
              id="startingBid"
              name="startingBid"
              min={0}
              precision={2}
              step={5}
            >
              <NumberInputField
                placeholder="50.00"
                type="number"
                {...formik.getFieldProps("startingBid")}
              />
            </NumberInput>
          </FormControl>
          {formik.touched.startingBid && formik.errors.startingBid ? (
            <p className="text-red-500 text-xs italic">
              {formik.errors.startingBid}
            </p>
          ) : null}

          <FormControl mb={8} isRequired>
            <FormLabel htmlFor="purchasePrice">Purchase Price</FormLabel>
            <Box fontSize="xs" color="gray.400">
              Enter the price at which this item can be purchased outright.
            </Box>
            <NumberInput
              id="purchasePrice"
              name="purchasePrice"
              min={0}
              precision={2}
              step={5}
            >
              <NumberInputField
                placeholder="50.00"
                type="number"
                {...formik.getFieldProps("purchasePrice")}
              />
            </NumberInput>
          </FormControl>
          {formik.touched.purchasePrice && formik.errors.purchasePrice ? (
            <p className="text-red-500 text-xs italic">
              {formik.errors.purchasePrice}
            </p>
          ) : null}

          <FormControl mb={8} isRequired>
            <FormLabel htmlFor="quantity">Quantity</FormLabel>
            <Box fontSize="xs" color="gray.400">
              How many of this item do you wish to donate?
            </Box>
            <NumberInput
              id="quantity"
              name="quantity"
              min={0}
              precision={2}
              step={5}
            >
              <NumberInputField
                placeholder="1"
                type="number"
                {...formik.getFieldProps("quantity")}
              />
            </NumberInput>
          </FormControl>
          {formik.touched.quantity && formik.errors.quantity ? (
            <p className="text-red-500 text-xs italic">
              {formik.errors.quantity}
            </p>
          ) : null}
        </Flex>

        <Flex
          flexDirection={{ base: "column", md: "row" }}
          justifyContent="space-between"
        >
          <FormControl isRequired mb={8}>
            <FormLabel htmlFor="condition">Ship or Deliver</FormLabel>
            <Box fontSize="xs" color="gray.400">
              Can you deliver the item to the organization's address.
            </Box>
            <RadioGroup
              id="shipOrDeliver"
              {...formik.getFieldProps("shipOrDeliver")}
              isInline
              variantColor="brand"
            >
              <Radio value="ship">Ship</Radio>
              <Radio value="deliver">Deliver</Radio>
            </RadioGroup>
          </FormControl>

          <FormControl mb={8} isRequired>
            <Flex alignItems="center">
              <Checkbox
                id="showCredit"
                value="showCredit"
                {...formik.getFieldProps("showCredit")}
              ></Checkbox>
              <FormLabel htmlFor="showCredit" ml={2}>
                Show Credit
              </FormLabel>
            </Flex>
            <Box fontSize="xs" color="gray.400">
              Your name will be displayed as the donor of this item.
            </Box>
          </FormControl>

          <FormControl mb={8} isRequired>
            <Flex alignItems="center">
              <Checkbox
                id="readStatement"
                {...formik.getFieldProps("readStatement")}
              ></Checkbox>
              <FormLabel htmlFor="readStatement" ml={2}>
                Agree to terms
              </FormLabel>
            </Flex>
            <Box fontSize="xs" color="gray.400">
              I have read the terms of use.
            </Box>
          </FormControl>
        </Flex>

        {/* {firebaseError && (
          <div className="text-red-500 text-sm"> {firebaseError}</div>
        )} */}
        <Flex justifyContent="flex-end">
          <Button
            type="submit"
            mr={6}
            variantColor="green"
            disabled={formik.isSubmitting}
          >
            Submit
          </Button>
          <Button type="button" variant="link">
            Cancel
          </Button>
        </Flex>
      </form>
    </Box>
  );
};

export default DonateForm;

import React from "react";
import { useParams, Link } from "react-router-dom";
import { MdChevronLeft } from "react-icons/md";

const Item = () => {
  const { id } = useParams();
  const formatter = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  return (
    <div className="">
      <nav>
        <Link to="/" className="link" title="Go Back">
          <MdChevronLeft /> Home
        </Link>
      </nav>
      A single item page
    </div>
  );
};

export default Item;

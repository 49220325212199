import React, { useState } from "react";
import firebase from "../../firebase/firebase";
import { Link, useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  FormControl,
  FormLabel,
  Input,
  Box,
  Button,
  Heading,
  Flex,
} from "@chakra-ui/core";

function Login(props) {
  const history = useHistory();
  const [login, setLogin] = useState(true);
  const [firebaseError, setFirebaseError] = useState(null);
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email address is required"),
      password: Yup.string()
        .min(6, "Password must be at least 6 characters long")
        .required("Please enter a password"),
    }),
    onSubmit: async (values) => {
      const { name, email, password } = values;

      try {
        login
          ? await firebase.login(email, password)
          : await firebase.register(name, email, password);
        history.push("/");
      } catch (err) {
        console.error("Authentication Error", err);
        setFirebaseError(err.message);
      }
    },
  });

  return (
    <Box w="65%" mt={16} mx="auto">
      <Heading as="h2" mb={8}>
        {login ? "Login" : "Create an  account"}
      </Heading>
      <form onSubmit={formik.handleSubmit} className="flex flex-column">
        {!login && (
          <FormControl mb={8}>
            <FormLabel htmlFor="name">Name</FormLabel>
            <Input
              id="name"
              placeholder="Your name"
              {...formik.getFieldProps("name")}
            />
          </FormControl>
        )}

        <FormControl isRequired mb={8}>
          <FormLabel htmlFor="email">Email</FormLabel>
          <Input
            id="email"
            placeholder="Your email address"
            {...formik.getFieldProps("email")}
            autocomplete="off"
            type="email"
          />
        </FormControl>
        {formik.touched.email && formik.errors.email ? (
          <p className="text-red-500 text-xs italic">{formik.errors.email}</p>
        ) : null}
        <FormControl isRequired mb={8}>
          <FormLabel htmlFor="password">Password</FormLabel>
          <Input
            id="password"
            placeholder="**********"
            {...formik.getFieldProps("password")}
            type="password"
          />
        </FormControl>
        {formik.touched.password && formik.errors.password && (
          <p className="text-red-500 text-xs italic">
            {formik.errors.password}
          </p>
        )}
        {firebaseError && (
          <div className="text-red-500 text-sm"> {firebaseError}</div>
        )}
        <Flex justifyContent="flex-end">
          <Button
            type="submit"
            mr={6}
            variantColor="green"
            disabled={formik.isSubmitting}
          >
            Submit
          </Button>
          <Button
            type="button"
            variant="link"
            onClick={() => setLogin((prevLogin) => !prevLogin)}
          >
            {login ? "need to create an account?" : "already have an account?"}
          </Button>
        </Flex>
      </form>
      <Box
        py={6}
        borderTopWidth={1}
        borderTopColor="gray.200"
        mt={8}
        textAlign="center"
      >
        <Link to="/forgot" color="teal.500">
          Forgot Password
        </Link>
      </Box>
    </Box>
  );
}

export default Login;

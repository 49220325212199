import React, { useContext } from "react";
import { Flex, Heading, Link, Button, Text } from "@chakra-ui/core";
import { NavLink } from "react-router-dom";
import FirebaseContext from "../../firebase/context";

const Header = () => {
  const { user, firebase } = useContext(FirebaseContext);

  return (
    <header className="">
      <Flex
        bg="brand.800"
        w="100%"
        px={5}
        py={4}
        justifyContent="space-between"
        alignItems="center"
      >
        <Heading as="h2" size="xl" color="white" outline={0}>
          <Link
            as={NavLink}
            to="/"
            border="none"
            outline={0}
            _hover={{ textDecoration: "none", color: "brand.400" }}
            _active={{ border: "none" }}
            _focus={{ outline: 0 }}
          >
            GiveRocket Auction
          </Link>
        </Heading>
        <Link
          as={NavLink}
          to="/donate"
          borderColor="brand.200"
          borderStyle="solid"
          borderWidth={1}
          px={4}
          py={2}
          color="brand.200"
          rounded={6}
          _hover={{
            color: "brand.500",
            borderColor: "brand.500",
            shadow: "0 0 3px 4px rgba(0,0,0,0.2)",
          }}
        >
          Donate!
        </Link>
        <Flex>
          {user ? (
            <Flex>
              <Text color="brand.300" mr={2}>
                {user.displayName}
              </Text>
              <Button
                variant="link"
                variantColor="yellow"
                onClick={() => firebase.logout()}
              >
                Logout
              </Button>
            </Flex>
          ) : (
            <Link as={NavLink} to="/login" px={2} color="brand.200">
              Login
            </Link>
          )}
        </Flex>
      </Flex>
    </header>
  );
};

export default Header;
